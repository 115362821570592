import React from "react";
import { Grid, Typography, Divider, Button } from "@mui/material";
import { CiLocationOn } from "react-icons/ci";
import { FiArrowRight } from "react-icons/fi";
import logo from "../../assets/logo.png";
import moreInfoCardImage from "../../assets/moreInfoCard.jpg";
import { HorizontalRule, InstallMobile } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./css/footer.css";
import "./css/responsive-footer.css";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div>
      <Grid container backgroundColor="#1c003d" color="white" spacing={2}>
        <Grid item xs={12} md={12} position="relative">
          <Grid container justifyContent="center" marginTop="30px" spacing={2}>
            <Grid item xs={10} md={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                        width: "100px",
                        marginBottom: "20px",
                      }}
                    >
                      <img
                        src={logo}
                        style={{ width: "100%", height: "100%" }}
                      />
                      <p style={{ fontWeight: 700, fontFamily: "Barlow" }}>
                        PACIFICWEB
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <CiLocationOn />
                    </div>

                    <p>
                      K-point Commercial Center Ayala Highway Sabang, Lipa City
                    </p>
                  </div>
                </Grid>

                <Grid item xs={3} md={2}>
                  <p className="footer-title">PACAKGES</p>
                  <div className="footer-sub-item-container">
                    <FiArrowRight />
                    <p>Commercial</p>
                  </div>
                  <div className="footer-sub-item-container">
                    <FiArrowRight />
                    <p>Residential</p>
                  </div>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={3} md={2}>
                  <p className="footer-title">ABOUT</p>
                  <div className="footer-sub-item-container">
                    <FiArrowRight />
                    <p>About</p>
                  </div>
                  <div className="footer-sub-item-container">
                    <FiArrowRight />
                    <p>Coverage</p>
                  </div>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Divider orientation="vertical" variant="middle" />
                </Grid>
                <Grid item xs={3} md={2}>
                  <p className="footer-title">CONTACTS</p>
                  <div className="footer-sub-item-container">
                    <FiArrowRight />
                    <p>Contact</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#616670",
            }}
          >
            <p>&copy; pacificweb2023</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
